import React, {useCallback, useEffect, useState} from 'react'

import {Form, Formik} from 'formik'

import {endOfDay, formatISO, isPast, isToday, startOfDay} from 'date-fns'
import {filter, forEach, isEmpty, isNil, isNull, isUndefined, join, omit, values} from 'lodash'

import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {RESOURCES, RESOURCES_V2} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {modalTypes} from '../../redux/modals'

import {tasksSchema} from '../../assets/validations'
import {objectKeysToSnakeCase} from '../../utils'
import {CANCELED_STATE, debounceWait} from '../../utils/constants'
import {useDebouncedEffect} from '../../utils/hooks'

import {Button} from '../Button'
import {ColorPicker} from '../ColorPicker'
import {DatePicker} from '../DatePicker'
import {ErrorsList} from '../ErrorComponents'
import {Input} from '../Input'
import {Modal} from '../Modal'
import {RequesterCreatable} from '../RequesterCreatable'
import {RequiredFieldsText} from '../RequiredFieldsText'
import {Select} from '../Select'
import {Toggle} from '../Toggle'

import './AddTaskModal.scss'

export const AddTaskModal = ({
    open,
    projectId,
    nonFieldErrors,
    fieldErrors,
    selectedEntityID,
    entityProfileID,
    entityProfiles,
    contracts,
    isLoadingContracts,
    searchContracts,
    listEntityProfiles,
    notifyPersons,
    createTask
}) => {
    const [projectsQuery, setProjectsQuery] = useState('')
    const [contractsQuery, setContractsQuery] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingProjects, setIsLoadingProjects] = useState(false)

    const [selectedProject, setSelectedProject] = useState(null)
    const [projects, setProjects] = useState([])

    const fetchProjects = async (search=null) => {
        setIsLoadingProjects(true)
        const {data} = await performRequest(RESOURCES_V2.projects.list(
            {entity_id: selectedEntityID, active: true, search:search})
        )
        setIsLoadingProjects(false)
        return data.results
    }

    useEffect(() => {
        const fetchInitialProject = async (projectId) => {
            setIsLoading(true)
            const {data} = await performRequest(RESOURCES_V2.projects.retrieve(projectId))
            setIsLoading(false)
            return data
        }

        if(open) {
            listEntityProfiles(selectedEntityID)
            if (projectId) {
                fetchInitialProject(projectId).then((data) => {
                    setSelectedProject(data)
                    handleFetchContracts()
                })
            }
            else {
                fetchProjects().then((data) => {
                    setProjects(data)
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listEntityProfiles, open, selectedEntityID])

    const handleFetchProjects = useCallback((query = projectsQuery) => {
        fetchProjects(query).then((data) => {
            setProjects(data)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectsQuery])

    useDebouncedEffect(handleFetchProjects, [projectsQuery], debounceWait)

    const handleChangeProjectsSearchField = useCallback((value) => setProjectsQuery(value), [])

    const handleFetchContracts = useCallback((query = contractsQuery) => {
        if(!isNil(selectedProject) && !isLoadingContracts) {
            let params = {project_id: selectedProject.id}
            searchContracts(query, params)
        }
    }, [isLoadingContracts, contractsQuery, searchContracts, selectedProject])

    useDebouncedEffect(handleFetchContracts, [contractsQuery, selectedProject], debounceWait)

    const handleChangeContractsSearchField = useCallback((value) => setContractsQuery(value), [])

    return (
        <Modal open={open} title='Adaugă sarcină'>
            {!isEmpty(entityProfiles) && !isLoadingContracts && (!isLoading) && (
                <>
                    <ErrorsList errors={nonFieldErrors} />
                    <Formik
                        initialValues={{
                            projectId: selectedProject,
                            contractId: !isNil(selectedProject) && contracts.length === 1 ?
                                contracts[0]: null,
                            ownersIds: filter(entityProfiles, (profile) => profile.id === entityProfileID),
                            supervisorsIds: [],
                            title: '',
                            requesterId: null,
                            timePlanning: true,
                            allDay: true,
                            start: new Date(),
                            stop: new Date(),
                            billable: true,
                            completion: false,
                            color: null,
                            taskSubscribers: [],
                            location: ''
                        }}
                        validationSchema={tasksSchema}
                        onSubmit={(values) => {
                            let start = null
                            let stop = null

                            if(values.timePlanning) {
                                if(values.allDay) {
                                    start = formatISO(startOfDay(values.start))
                                    stop = formatISO(endOfDay(values.stop))
                                } else {
                                    start = formatISO(values.start)
                                    stop = formatISO(values.stop)
                                }
                            }

                            const taskData = {
                                ...objectKeysToSnakeCase({
                                    ...omit(values, [
                                        'projectId',
                                        'contractId',
                                        'ownersIds',
                                        'supervisorsIds',
                                        'requesterId',
                                        'date',
                                        'taskSubscribers',
                                        'timePlanning'
                                    ])
                                }),
                                entity_id: selectedEntityID,
                                owners_ids: values.ownersIds.map((profile) => profile.id),
                                supervisors_ids: values.supervisorsIds.map((profile) => profile.id),
                                project_id: values.projectId?.id || null,
                                contract_id: values.contractId?.id || null,
                                requester_id: values.requesterId?.id || null,
                                all_day: values.timePlanning ? values.allDay : null,
                                start: start,
                                stop: stop,
                                completion: values.completion ? new Date() : null,
                                task_subscribers: values.taskSubscribers.map((sub) => ({
                                    subscriber_id: sub.value,
                                    subscriber_category: sub.subscriber_category
                                }))
                            }

                            createTask(taskData)
                        }}
                    >
                        {({
                            handleChange,
                            setFieldValue,
                            handleBlur,
                            handleSubmit,
                            values,
                            errors,
                            touched,
                            isValid
                        }) => (
                            <Form className='add-task-form-container'>
                                <div className="add-task-form-row">
                                    <Select
                                        label='Proiect*'
                                        value={values.projectId}
                                        options={projects}
                                        getOptionValue={(option) => option.id}
                                        getOptionLabel={(option) => (
                                            option.name + (option.clients_names.length
                                                ? ` (${join(option.clients_names, ', ')})` : '')
                                        )}
                                        onChange={(option) => {
                                            setSelectedProject(option)
                                            setFieldValue('projectId', option)

                                            setFieldValue('taskSubscribers', [])
                                            setFieldValue('contractId', null)
                                        }}
                                        onInputChange={(value) => handleChangeProjectsSearchField(value)}
                                        onBlur={handleBlur('projectId')}
                                        disabled={!isUndefined(projectId)}
                                        name='projectId'
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.projectId}
                                        loading={isLoadingProjects}
                                        fullWidth
                                    />
                                    <Select
                                        label='Contract'
                                        value={values.contractId}
                                        options={contracts}
                                        loading={isLoadingContracts}
                                        getOptionValue={(option) => option.id}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(e) => setFieldValue('contractId', e)}
                                        onInputChange={(value) => handleChangeContractsSearchField(value)}
                                        onBlur={handleBlur('contractId')}
                                        name='contractId'
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.contractId}
                                        disabled={isNull(values.projectId)}
                                        fullWidth
                                        isClearable
                                    />
                                </div>
                                <div className="add-task-form-row">
                                    <Select
                                        label='Deținători*'
                                        value={values.ownersIds}
                                        options={entityProfiles}
                                        getOptionValue={(option) => option.id}
                                        getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                                        onChange={(e) => setFieldValue('ownersIds', e)}
                                        onBlur={handleBlur('ownersIds')}
                                        name='ownersIds'
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.ownersIds}
                                        fullWidth
                                        isMulti
                                    />
                                    <Select
                                        label='Supraveghetori'
                                        value={values.supervisorsIds}
                                        options={entityProfiles}
                                        getOptionValue={(option) => option.id}
                                        getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                                        onChange={(e) => setFieldValue('supervisorsIds', e)}
                                        onBlur={handleBlur('supervisorsIds')}
                                        name='supervisorsIds'
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.supervisorsIds}
                                        isClearable
                                        fullWidth
                                        isMulti
                                    />
                                </div>
                                <Input
                                    label='Titlu/Descriere sarcină'
                                    value={values.title}
                                    onChange={handleChange('title')}
                                    onBlur={handleBlur('title')}
                                    name='title'
                                    errors={fieldErrors}
                                    frontendErrors={errors}
                                    touched={touched.title}
                                    fullWidth
                                />
                                <RequesterCreatable
                                    value={values.requesterId}
                                    onChange={(e) => setFieldValue('requesterId', e)}
                                    onBlur={handleBlur('requesterId')}
                                    name='requesterId'
                                    errors={fieldErrors}
                                    frontendErrors={errors}
                                    touched={touched.requesterId}
                                    disabled={isNull(values.projectId)}
                                    projectId={values.projectId?.id || null}
                                />
                                <Toggle
                                    label='Planificare timp'
                                    checked={values.timePlanning}
                                    onChange={(e) => setFieldValue('timePlanning', e)}
                                    onBlur={handleBlur('timePlanning')}
                                    name='timePlanning'
                                    errors={fieldErrors}
                                    frontendErrors={errors}
                                    touched={touched.timePlanning}
                                />
                                {values.timePlanning && (
                                    <>
                                        <Toggle
                                            label='Toată ziua'
                                            checked={values.allDay}
                                            onChange={(e) => setFieldValue('allDay', e)}
                                            onBlur={handleBlur('allDay')}
                                            name='allDay'
                                            errors={fieldErrors}
                                            frontendErrors={errors}
                                            touched={touched.allDay}
                                        />
                                        <div className={`add-task-form-row ${!values.allDay ? 'asym-split' : ''}`}>
                                            <DatePicker
                                                label='Data start*'
                                                value={values.start}
                                                onChange={(date) => isNull(date) ?
                                                    setFieldValue('start', date) :
                                                    setFieldValue('start', new Date(date))
                                                }
                                                onBlur={handleBlur('start')}
                                                name='start'
                                                errors={fieldErrors}
                                                frontendErrors={errors}
                                                touched={touched.start}
                                                fullWidth
                                            />
                                            {!values.allDay && (
                                                <DatePicker
                                                    label='Ora start'
                                                    value={values.start}
                                                    onChange={(date) => setFieldValue('start', new Date(date))}
                                                    timeFormat='HH:mm'
                                                    timeSelect
                                                    fullWidth
                                                />
                                            )}
                                        </div>
                                        <div className={`add-task-form-row ${!values.allDay ? 'asym-split' : ''}`}>
                                            <DatePicker
                                                label='Data stop*'
                                                value={values.stop}
                                                onChange={(date) => isNull(date) ?
                                                    setFieldValue('stop', date) :
                                                    setFieldValue('stop', new Date(date))
                                                }
                                                onBlur={handleBlur('stop')}
                                                name='stop'
                                                errors={fieldErrors}
                                                frontendErrors={errors}
                                                touched={touched.stop}
                                                fullWidth
                                            />
                                            {!values.allDay && (
                                            <DatePicker
                                                label='Ora stop'
                                                value={values.stop}
                                                onChange={(date) => setFieldValue('stop', new Date(date))}
                                                timeFormat='HH:mm'
                                                timeSelect
                                                fullWidth
                                            />
                                            )}
                                        </div>
                                    </>
                                )}
                                <div className="add-task-form-row">
                                    <div className="w-full">
                                        <Toggle
                                            label='De facturat'
                                            checked={values.billable}
                                            onChange={(e) => setFieldValue('billable', e)}
                                            onBlur={handleBlur('billable')}
                                            name='billable'
                                            errors={fieldErrors}
                                            frontendErrors={errors}
                                            touched={touched.billable}
                                        />
                                    </div>
                                    <div className="w-full">
                                        {isPast(values.start) && !isToday(values.start) && (
                                            <Toggle
                                                label='Sarcină finalizată'
                                                checked={values.completion}
                                                onChange={(e) => setFieldValue('completion', e)}
                                                onBlur={handleBlur('completion')}
                                                name='completion'
                                                errors={fieldErrors}
                                                frontendErrors={errors}
                                                touched={touched.completion}
                                            />
                                        )}
                                    </div>
                                </div>
                                <Select
                                    label='Colaboratori interni anunțați'
                                    value={values.taskSubscribers}
                                    options={notifyPersons}
                                    disabled={isNull(values.projectId)}
                                    onChange={(e) => setFieldValue('taskSubscribers', e)}
                                    onBlur={handleBlur('taskSubscribers')}
                                    name='taskSubscribers'
                                    errors={fieldErrors}
                                    frontendErrors={errors}
                                    touched={touched.taskSubscribers}
                                    fullWidth
                                    isMulti
                                />
                                <div className="add-task-form-row">
                                    <ColorPicker
                                        label='Culoare'
                                        color={values.color}
                                        onChange={(hex) => setFieldValue('color', hex)}
                                        onBlur={handleBlur('color')}
                                        name='color'
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.color}
                                        isClearable
                                    />
                                    <Input
                                        label='Locație'
                                        value={values.location}
                                        onChange={handleChange('location')}
                                        onBlur={handleBlur('location')}
                                        name='location'
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.location}
                                        fullWidth
                                    />
                                </div>
                                <RequiredFieldsText />
                                <Button
                                    title='Adaugă sarcină'
                                    onClick={handleSubmit}
                                    disabled={!isValid}
                                    loading={isLoading}
                                    color='secondary'
                                    type='submit'
                                    fullWidth
                                />
                            </Form>
                        )}
                    </Formik>
                </>
            )}
        </Modal>
    )
}

const mapStateToProps = (state) => {
    const getPersons = () => {
        let persons = []

        const entityProfiles = values(state.entityProfiles.data)

        // const contactPersons = values(state.contactPersons.data)
        // forEach(contactPersons, (person) => {
        //     persons.push({
        //         value: person.id,
        //         label: `${person.first_name} ${person.last_name}`,
        //         subscriber_category: 'contact-persons'
        //     })

        forEach(entityProfiles, (person) => {
            persons.push({
                value: person.id,
                label: `${person.first_name} ${person.last_name}`,
                subscriber_category: 'entity-profiles'
            })
        })

        return persons
    }

    return {
        open: state.modals.type === modalTypes.ADD_TASK,
        isLoading: state.tasks.isLoading,
        fieldErrors: state.tasks.fieldErrors,
        nonFieldErrors: state.tasks.nonFieldErrors,
        entityProfiles: values(state.entityProfiles.data),
        contracts: !state.contracts.isLoading ? values(state.contracts.searchData) : [],
        isLoadingContracts: state.contracts.isLoading,
        notifyPersons: getPersons(),
        selectedEntityID: state.localConfigs.selectedEntityID,
        entityProfileID: state.localConfigs.entityProfileID
    }
}

const mapDispatchToProps = (dispatch) => ({
    listEntityProfiles: (entityID) => dispatch(RESOURCES.entityProfiles.listAll({entity_id:  entityID})),
    searchContracts: (search, params) => dispatch(RESOURCES.contracts.search(search, {
        ...params, state_not: [CANCELED_STATE]
    })),
    createTask: (data) => dispatch(RESOURCES.tasks.create(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddTaskModal)