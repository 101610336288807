import React, {useCallback, useMemo} from 'react'
import {Link} from 'react-router-dom'

import {lightFormat} from 'date-fns'
import _ from 'lodash'

// @ts-ignore
import {setCurrent as setCurrentBpiSubscription} from 'avoapp-react-common/dist/redux/bpiSubscriptions'
// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
// @ts-ignore
import {modalTypes, openModal} from '../../../redux/modals'

import {Cell} from 'types/local'
import {useQueryResourceList} from '../../../queries/rest'
// @ts-ignore
import {useDebouncedState} from '../../../utils/hooks'

// @ts-ignore
import {CheckCell} from '../../../components/CheckCell'
// @ts-ignore
import {Datatable} from '../../../components/Datatable'
// @ts-ignore
import {DatatableRowButtons} from '../../../components/DatatableRowButtons'
// @ts-ignore
import {DeleteBpiSubscriptionModal} from '../../../components/DeleteBpiSubscriptionModal'
// @ts-ignore
import {Button} from '../../../components/Button'
import {AddBPISubscriptionModal} from '../../../components/AddBPISubscriptionModal'
import {TableLink} from '../../../components/TableLink'

interface MonitorBpiDatatableProps {
    openModal: (modalType: string) => void
    openedModal: string
    selectedEntityID: string
    setCurrentBpiSubscription:(bpiSubscription: any) => void
}

export const MonitorBpiDatatable = ({
    openModal,
    openedModal,
    selectedEntityID,
    setCurrentBpiSubscription
}: MonitorBpiDatatableProps) => {
    const [searchFilter, setSearchFilter] = useDebouncedState('', 300)
    const [page, setPage] = React.useState<number>(1)

    const filters = useMemo(() => ({
        search: searchFilter,
        entity_id: selectedEntityID,
        page_size: 15,
        page: page
    }), [page, searchFilter, selectedEntityID])

    const {data: bpiSubRes, isFetching} = useQueryResourceList(
        RESOURCES.bpiSubscriptions,
        filters,
        undefined,
        //we want to refetch the data when the delete modal closes
        _.isNil(openedModal).toString()
    )

    const bpiSubscriptionsResult = useMemo(() => {
        // To make typescript happy
        return bpiSubRes as any
    }, [bpiSubRes])

    const handleChangeSubscriptionsSearchField = (value: string) => setSearchFilter(value)

    const handleChangeSubscriptionsPage = useCallback((newPage: number) => {setPage(newPage)}, [])

    const columns = useMemo(() => {
        return [
            {
                Header: 'Nume companie',
                accessor: 'company_name',
                Cell: ({value, row: {original: bpiSubscription}}: Cell) => <TableLink
                    to={`/projects/${bpiSubscription.project.id}/external-data/monitor-bpi`}
                >{value}</TableLink>
            },
            {
                Header: 'CUI',
                accessor: 'vat_number'
            },
            {
                Header: 'Proiect',
                accessor: 'project',
                Cell: ({value}: Cell) => <TableLink to={`/projects/${value?.id}`}>{value?.name}</TableLink>
            },
            {
                Header: 'Clienți',
                accessor: 'project.clients',
                Cell: ({value}: Cell) => <div style={{display: 'flex', flexWrap: 'wrap'}}>{value.map(
                    (client: any, index: number) => <>
                        <TableLink to={`/clients/${client.id}`}>{client.name}</TableLink>
                        {index + 1 !== value.length && ', '}
                    </>
                )}</div>
            },
            {
                Header: 'Activ',
                accessor: 'active',
                Cell: ({value}: Cell) => value && <CheckCell />
            },
            {
                Header: 'Ultima actualizare',
                accessor: 'latest_update',
                Cell: ({value}: Cell) => !_.isNil(value) ? lightFormat(new Date(value), 'dd/MM/yyyy HH:mm') : '-'
            },
            {
                Header: 'Acțiuni',
                accessor: 'id',
                Cell: ({row: {original: bpiSubscription}}: Cell) => (
                    <div className='datatable-row-buttons-container'>
                        <Link to={`/projects/${bpiSubscription.project.id}/external-data/monitor-bpi`}>
                            <Button title='Vizualizare' size='small' />
                        </Link>
                        <DatatableRowButtons
                            onDeleteButtonClick={() => {
                                setCurrentBpiSubscription(bpiSubscription)
                                openModal(modalTypes.DELETE_BPI_SUBSCRIPTION)
                            }}
                        />
                    </div>
                )
            }
        ]
    }, [openModal, setCurrentBpiSubscription])

    return (
        <div className='page-external-data-show'>
            <Datatable
                title='Listă părți monitorizate'
                data={bpiSubscriptionsResult?.data.results || []}
                headerButton={() => (
                    <Button
                        title='Adaugă abonare'
                        onClick={() => openModal(modalTypes.ADD_MONITOR_BPI_SUBSCRIPTION)}
                        color='secondary'
                    />
                )}
                columns={columns}
                loading={isFetching}
                previousPage={bpiSubscriptionsResult?.data?.pages?.previous}
                currentPage={bpiSubscriptionsResult?.data?.page}
                nextPage={bpiSubscriptionsResult?.data?.pages?.next}
                totalPages={bpiSubscriptionsResult?.data?.number_of_pages}
                onChangePage={(page: number) => handleChangeSubscriptionsPage(page)}
                searchable
                searchPlaceholder='Caută surse de date'
                onSearch={(event: any) => handleChangeSubscriptionsSearchField(event.target.value)}
            />
            <AddBPISubscriptionModal />
            <DeleteBpiSubscriptionModal />
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    openedModal: state.modals.type,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch: any) => ({
    openModal: (modalType: string) => dispatch(openModal(modalType)),
    setCurrentBpiSubscription: (subscription: any) => dispatch(setCurrentBpiSubscription(subscription))
})

export default connect(mapStateToProps, mapDispatchToProps)(MonitorBpiDatatable)