import React, {useState} from 'react'
import {Link} from 'react-router-dom'

import {isEmpty, isNil, orderBy} from 'lodash'

import {uploadSignedFile} from 'avoapp-react-common/dist/redux/contracts'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {removeFilter} from '../../../../redux/filters/filters'
import {generateFiltersForAPI} from '../../../../redux/filters/utils'

import {CANCELED_STATE, debounceWait} from '../../../../utils/constants'
import {datatablePageSize} from '../../../../utils/datatables'
import {useDebouncedEffect} from '../../../../utils/hooks'

import {Button} from '../../../../components/Button'
import {Datatable} from '../../../../components/Datatable'
import {Dropdown} from '../../../../components/Dropdown'
import {SignedFileDropzone} from '../../../../components/SignedFileDropzone'

import FilterContractsForm from '../forms/FilterContractsForm'
import {columns} from './constants'

import './ContractsList.scss'
import {getTokenAndUploadFile} from '../../../../utils/files'
import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {toast} from 'react-toastify'

export const ContractsList = ({
    contracts,
    isLoading,
    previousPage,
    currentPage,
    nextPage,
    totalPages,
    filters,
    removeFilter,
    listContracts,
    selectedEntityID
}) => {
    const [searchFilter, setSearchFilter] = useState('')

    const handleFetchContracts = (search = searchFilter, page = 1) => {
        const appliedFilters = generateFiltersForAPI(filters)

        listContracts(search, appliedFilters, page)
    }

    useDebouncedEffect(handleFetchContracts, [searchFilter], debounceWait)

    const handleChangeSearchField = (value) => setSearchFilter(value)

    const handleChangePage = (page) => !isLoading && handleFetchContracts(searchFilter, page)

    const handleDropSignedFile = async (selectedFile, contractID) => {
        if(!isEmpty(selectedFile)) {
            const fileURL = await getTokenAndUploadFile(selectedFile, selectedEntityID)
            await performRequest(RESOURCES.contracts.update({signed_file: fileURL}, contractID))
            toast.success('Documentul semnat a fost încărcat cu succes.')
        }
    }

    return (
        <Datatable
            data={contracts}
            columns={[
                ...columns,
                {
                    Header: 'Acțiuni',
                    accessor: 'id',
                    Cell: ({value: contractID, row: {original: contract}}) => (
                        <div className='flex gap-2 items-center'>
                            <Link to={`/contracts/${contractID}/preview`}>
                                <Button title='Vizualizare' variant='text' size='small' />
                            </Link>
                            <Dropdown
                                title='Descarcă'
                                disabled={isNil(contract.file) && isNil(contract.signed_file)}
                                items={[
                                    {
                                        customContent: () => contract.file ? (
                                            <a
                                                href={contract.file}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className='avo-dropdown-menu-item'
                                            >
                                                Descarcă
                                            </a>
                                        ) : <React.Fragment />
                                    },
                                    {
                                        customContent: () => contract.signed_file ? (
                                            <a
                                                href={contract.signed_file}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className='avo-dropdown-menu-item'
                                            >
                                                Descarcă fișier semnat
                                            </a>
                                        ) : <React.Fragment />
                                    }
                                ]}
                                variant='text'
                                color='secondary'
                                size='small'
                            />
                            <Dropdown
                                title='Încarcă'
                                items={[
                                    {
                                        customContent: () => (
                                            <SignedFileDropzone
                                                onChange={(selectedFile) =>
                                                    handleDropSignedFile(selectedFile, contractID)
                                                }
                                            />
                                        )
                                    }
                                ]}
                                variant='text'
                                color='orange'
                                size='small'
                            />
                        </div>
                    )
                }
            ]}
            previousPage={previousPage}
            currentPage={currentPage}
            nextPage={nextPage}
            totalPages={totalPages}
            onChangePage={(page) => handleChangePage(page)}
            filterable
            filters={filters}
            filtersForm={() => <FilterContractsForm filterContracts={handleFetchContracts} />}
            handleRemoveFilter={(filter) => {
                removeFilter(filter)
                handleFetchContracts()
            }}
            searchable
            searchValue={searchFilter}
            searchPlaceholder='Caută contracte'
            onSearch={(event) => handleChangeSearchField(event.target.value)}
            rowProps={row => ({
                className: row.original.state === CANCELED_STATE ? 'strikeout-row' : null
            })}
        />
    )
}

const mapStateToProps = (state) => ({
    selectedEntityID: state.localConfigs.selectedEntityID,
    contracts: orderBy(
        state.contracts.data,
        ['signed_date', 'internal_series_name', 'internal_series_number'], ['desc', 'asc', 'desc']
    ),
    isLoading: state.contracts.isLoading,
    totalPages: state.contracts.totalPages,
    nextPage: state.contracts.next,
    previousPage: state.contracts.previous,
    currentPage: state.contracts.current,
    filters: state.filters.contracts
})

const mapDispatchToProps = (dispatch) => ({
    removeFilter: (filter) => dispatch(removeFilter(RESOURCES.contracts.name, filter)),
    uploadSignedFile: (contractData, contractID) => dispatch(uploadSignedFile(contractData, contractID)),
    listContracts: (search, filters, page) => dispatch(
        RESOURCES.contracts.list(
            {
                ...filters,
                search: search,
                page: page,
                page_size: datatablePageSize
            },
            // overwriteData
            true
        )
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(ContractsList)