export const prefix = '/expenses'

export const actionIds = {
    LIST: 'list',
    PAYMENTS: 'payments'
}

export const actions = {
    [actionIds.LIST]: {
        id: actionIds.LIST,
        label: 'Cheltuieli'
    },
    [actionIds.PAYMENTS]: {
        id: actionIds.PAYMENTS,
        label: 'Încasări cheltuieli'
    }
}