
export const prefix: string = '/external-data'

export const actionIds = {
    MONITOR_DOSARE: 'monitor-dosare',
    MONITOR_DOSARE_HEARINGS: 'hearings',
    MONITOR_DOSARE_SUBSCRIPTIONS: 'subscriptions',
    MONITOR_DOSARE_SUBSCRIPTIONS_PARTS: 'subscriptions-parts',

    MONITOR_BPI: 'monitor-bpi',
    MONITOR_BPI_NOTIFICATIONS: 'notifications',
    MONITOR_BPI_APPEARANCES: 'bpi-appearances',
    MONITOR_BPI_SUBSCRIPTIONS: 'subscriptions'
}

export const actions = {
    [actionIds.MONITOR_DOSARE]: {
        id: actionIds.MONITOR_DOSARE,
        label: 'Monitor Dosare',
        secondaryActions: [
            {
                id: actionIds.MONITOR_DOSARE_HEARINGS,
                label: 'Termene'
            },
            {
                id: actionIds.MONITOR_DOSARE_SUBSCRIPTIONS,
                label: 'Dosare monitorizate'
            },
            {
                id: actionIds.MONITOR_DOSARE_SUBSCRIPTIONS_PARTS,
                label: 'Părți monitorizate'
            }
        ]
    },
    [actionIds.MONITOR_BPI]: {
        id: actionIds.MONITOR_BPI,
        label: 'Monitor BPI',
        secondaryActions: [
            {
                id: actionIds.MONITOR_BPI_APPEARANCES,
                label: 'Apariții'
            },
            {
                id: actionIds.MONITOR_BPI_SUBSCRIPTIONS,
                label: 'Abonări'
            }
        ]
    }
}
