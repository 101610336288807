import React, {useCallback, useEffect, useMemo, useState} from 'react'

import {keepPreviousData} from '@tanstack/react-query'
import {lightFormat} from 'date-fns'
import _ from 'lodash'

import {BPIAppearance, BPIDocument, BPISubscription} from 'types/api'

// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
// @ts-ignore
import {openSlideover, slideoverTypes} from '../../redux/slideovers'

import {useQueryResourceList} from '../../queries/rest'

// @ts-ignore
import {Button} from '../../components/Button'
// @ts-ignore
import {Datatable} from '../../components/Datatable'

// @ts-ignore
import {useDebouncedState} from '../../utils/hooks'

import {BPIAppearanceDetailsSlideover} from './partials'

import './BPIAppearances.scss'

type BPIAppearancesProps = {
    openSlideover: (slideoverType: string) => void
    selectedEntityID: number
}

export const BPIAppearances = ({
    openSlideover, selectedEntityID
}: BPIAppearancesProps) => {

    const [currentAppearance, setCurrentAppearance] = useState<BPIAppearance | undefined>(undefined)
    const [appearances, setAppearances] = useState<BPIAppearance[] | undefined>(undefined)
    const [page, setPage] = useState<number>(1)
    const [searchValue, setSearchValue] = useDebouncedState('', 300)

    const filters = useMemo(() => ({
        page_size: 15,
        entity_id: selectedEntityID,
        ordering: '-document_date',
        search: searchValue,
        page
    }), [page, selectedEntityID, searchValue])

    const {data, isFetching} = useQueryResourceList(
        RESOURCES.bpiAppearances,
        filters,
        {
            placeholderData: keepPreviousData
        }
    )

    const appearancesResult = useMemo(() => {
        // To make typescript happy
        return data as any
    }, [data])

    const handleChangePage = useCallback((page) => setPage(page), [])

    const onClickView = useCallback((appearance: BPIAppearance) => {
        setCurrentAppearance(appearance)
    }, [setCurrentAppearance])

    useEffect(() => {
        openSlideover(slideoverTypes.BPI_APPEARANCE_DETAILS)
    }, [currentAppearance, openSlideover])

    useEffect(() => {
        if(!_.isNil(appearancesResult?.data?.results) && !isFetching) {
            setAppearances(appearancesResult.data.results)
        } else if(!isFetching) {
            setAppearances([])
        }
    }, [isFetching, appearancesResult])

    const columns = useMemo(() => {
        return [
            {
                Header: 'Data',
                accessor: 'document',
                Cell: ({value}: {value: BPIDocument}) => lightFormat(new Date(value.date), 'dd/MM/yyyy')
            },
            {
                Header: 'CUI (Companie)',
                accessor: '_id',
                Cell: ({row: {original: appearance}}: {row: { original: BPIAppearance }}) => (
                    <p>
                        {appearance.vat_number}
                        {appearance.company_name && <span> ({appearance.company_name})</span>}
                    </p>
                )
            },
            {
                Header: 'Proiect',
                accessor: 'subscription',
                Cell: ({value}: {value: BPISubscription}) => (
                    <p>{value?.project? value.project.name : '-'}</p>
                )
            },
            {
                Header: 'Acțiuni',
                accessor: 'id',
                Cell: ({row: {original: appearance}}: {row: { original: BPIAppearance }}) => (
                    <Button
                        variant='text'
                        title='Vizualizare'
                        onClick={() => onClickView(appearance)}
                        size='small'
                    />
                )
            }
        ]

    }, [onClickView])

    if(_.isNil(appearancesResult) || _.isNil(appearances)) {
        return <React.Fragment />
    }

    return (
        <>
            <Datatable
                title='Listă apariții'
                data={appearances}
                columns={columns}
                loading={isFetching}
                previousPage={appearancesResult.data?.pages?.previous}
                currentPage={appearancesResult.data?.page}
                nextPage={appearancesResult.data?.pages?.next}
                totalPages={appearancesResult.data?.number_of_pages}
                onChangePage={(page: number) => handleChangePage(page)}
                searchable
                searchPlaceholder='Caută apariții'
                onSearch={(event: React.ChangeEvent<HTMLInputElement>) => {setSearchValue(event.target.value)}}
            />
            {
                currentAppearance && <BPIAppearanceDetailsSlideover appearance={currentAppearance} />
            }
        </>
    )
}

const mapStateToProps = (state: any) => ({
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch: any) => ({
    openSlideover: (slideoverType: string) => dispatch(openSlideover(slideoverType))
})

export default connect(mapStateToProps, mapDispatchToProps)(BPIAppearances)
