import React, {useCallback} from 'react'

import {ArrowTopRightOnSquareIcon} from '@heroicons/react/24/solid'
import _ from 'lodash'

import {BPIAppearance} from 'types/api'

// @ts-ignore
import {connect} from 'react-redux'
// @ts-ignore
import {slideoverTypes} from '../../../../redux/slideovers'

// @ts-ignore
import {Slideover} from '../../../../components/Slideover'
// @ts-ignore
import {Button} from '../../../../components/Button'

type BPIAppearanceDetailsSlideoverProps = {
    open: boolean
    appearance: BPIAppearance
}

export const BPIAppearanceDetailsSlideover = ({open, appearance}: BPIAppearanceDetailsSlideoverProps) => {
    const buttonIconRenderer = useCallback(
        () => {
            return <ArrowTopRightOnSquareIcon />
        },
        []
    )

    return (
        <Slideover open={open && !_.isEmpty(appearance)} title="Aparitie">
            <div className="notification-details-container">
                <div className="notification-details-texts-container">
                    <p className="notification-details-changes-text">Mesaj</p>
                    <p className="notification-details-message">{appearance.text_shortened}</p>
                </div>
                <a href={appearance.document?.file_url} target="_blank" rel="noopener noreferrer">
                    <Button
                        title='Vezi documentul'
                        icon={buttonIconRenderer}
                        className='button-cta'
                        fullWidth
                    />
                </a>
            </div>
        </Slideover>
    )
}

const mapStateToProps = (state: any) => ({
    open: state.slideovers.type === slideoverTypes.BPI_APPEARANCE_DETAILS
})

export default connect(mapStateToProps)(BPIAppearanceDetailsSlideover)
