import React from 'react'

import {isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {closeModal, modalTypes} from '../../redux/modals'

import {Modal} from '../Modal'
import {Button} from '../Button'

import '../../assets/scss/DeleteModals.scss'
import {CANCELED_STATE} from '../../utils/constants'

function DeleteContractModal({
    openForDelete,
    openForCancel,
    contract,
    isLoading,
    closeModal,
    deleteContract,
    cancelContract
}) {
    return (
        <Modal
            open={(openForDelete || openForCancel) && !isEmpty(contract)}
            title={openForDelete ? 'Șterge contract' : 'Anulează contract'}
        >
            <div className='delete-modal'>
                <p className='message'>
                    Ești sigur că vrei să {openForDelete ? 'ștergi' : 'anulezi'} contractul
                    "<span>{contract.name}</span>"?
                </p>
                <div className="buttons-container">
                    <Button
                        title='Nu, renunță'
                        onClick={closeModal}
                        variant='outlined'
                        loading={isLoading}
                        color='gray'
                    />
                    <Button
                        title={openForDelete ? 'Da, șterge' : 'Da, anulează'}
                        onClick={() => openForDelete ? deleteContract(contract) : cancelContract(contract)}
                        loading={isLoading}
                        color='red'
                    />
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    openForDelete : state.modals.type === modalTypes.DELETE_CONTRACT,
    openForCancel : state.modals.type === modalTypes.CANCEL_CONTRACT,
    contract: state.contracts.currentContract,
    isLoading: state.contracts.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    deleteContract: (contract) => dispatch(RESOURCES.contracts.destroy(contract)),
    cancelContract: (contract) => dispatch(RESOURCES.contracts.update({state: CANCELED_STATE}, contract.id))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteContractModal)