import React, {useCallback, useEffect, useState} from 'react'

import {isEmpty, values} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {push} from 'connected-react-router'
import {connect} from 'react-redux'
import {generateFiltersForAPI} from '../../../../redux/filters/utils'
import {modalTypes, openModal} from '../../../../redux/modals'

import {datatablePageSize} from '../../../../utils/datatables'

import {AddExpensePaymentModal} from '../../../../components/AddExpensePaymentModal'
import {Button} from '../../../../components/Button'
import {Datatable} from '../../../../components/Datatable'
import {DatatableRowButtons} from '../../../../components/DatatableRowButtons'
import {DeleteExpenseModal} from '../../../../components/DeleteExpenseModal'

import {columns, columnsExtended} from './constants'

import './ExpensePaymentsList.scss'
import {ArrowDownTrayIcon} from '@heroicons/react/24/solid'
import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {toast} from 'react-toastify'
import fileDownload from 'js-file-download'

const ExpensePaymentsList = ({
    match: {params: {projectID, clientID}},
    expensePayments,
    isLoading,
    totalPages,
    nextPage,
    previousPage,
    currentPage,
    filters,
    selectedEntityID,
    listExpensePayments,
    navigate,
    openAddExpensePaymentModal,
    hasCreateOption,
    openDeleteExpenseModal,
    fullscreen=false
}) => {
    const [searchFilter] = useState('')
    const [selectedExpensePayment, setSelectedExpensePayment] = useState(null)
    const [isPendingExport, setIsPendingExport] = useState(false)

    const handleFetchExpensePayments = useCallback((query = searchFilter, page = 1) => {
        const appliedFilters = {
            ...generateFiltersForAPI(filters),
            ...(projectID ? {project_id: projectID} : {}),
            ...(clientID ? {client_id: clientID} : {})
        }

        listExpensePayments(selectedEntityID, query, appliedFilters, page)
    }, [filters, listExpensePayments, projectID, clientID, searchFilter, selectedEntityID])

    useEffect(() => { handleFetchExpensePayments() }, [handleFetchExpensePayments])

    const handleChangePage = (page) => !isLoading && handleFetchExpensePayments(searchFilter, page)

    return (
        <div className="page-info" style={
            fullscreen ? {} : {display: 'inline-block', width: '39%', verticalAlign: 'top', marginLeft: '10px'}
        }>
            <Datatable
                title='Încasări cheltuieli'
                data={expensePayments}
                columns={[
                    ...(fullscreen? columnsExtended : columns),
                    {
                        Header: 'Acțiuni',
                        accessor: 'id',
                        Cell: ({value: expensePaymentID, row: {original: expensePayment}}) => (
                            <DatatableRowButtons
                                mainButtonTitle='Vezi'
                                onMainButtonClick={() => {
                                    navigate(`/expense-payments/${expensePaymentID}`)
                                }}
                                onDeleteButtonClick={() => {
                                    setSelectedExpensePayment(expensePayment)
                                    openDeleteExpenseModal()
                                }}
                            />
                        ),
                        style: {width: '75px'}
                    }
                ]}
                loading={isLoading}
                headerButton={() => (
                    <>
                        <span style={{marginRight: '10px'}}>
                            <Button
                                loading={isPendingExport}
                                disabled={isEmpty(expensePayments) || isPendingExport}
                                title='Descarcă CSV'
                                icon={() => <ArrowDownTrayIcon/>}
                                onClick={async () => {
                                    setIsPendingExport(true)

                                    const appliedFilters = {
                                        ...generateFiltersForAPI(filters),
                                        ...(projectID ? {project_id: projectID} : {}),
                                        ...(clientID ? {client_id: clientID} : {})
                                    }

                                    const response = await performRequest(RESOURCES.expenses.listCSV(
                                        {...appliedFilters, entity_id: selectedEntityID, search: searchFilter}
                                    ))
                                    setIsPendingExport(false)

                                    if (response.status === 200) {
                                        toast.success('Fișierul CSV a fost generat.')
                                        fileDownload(response.data, 'export-cheltuieli.csv')
                                    } else {
                                        toast.error('A apărut o eroare la generarea raportului.')
                                    }
                                }}
                            />
                        </span>
                        {hasCreateOption && (
                            <Button title='Adaugă încasare' color='secondary' onClick={openAddExpensePaymentModal}/>
                        )}
                    </>
                )}
                nextPage={nextPage}
                previousPage={previousPage}
                currentPage={currentPage}
                totalPages={totalPages}
                onChangePage={(page) => handleChangePage(page)}
            />
            <AddExpensePaymentModal projectId={projectID}/>
            <DeleteExpenseModal selectedExpensePayment={selectedExpensePayment}/>
        </div>
    )
}

const mapStateToProps = (state) => ({
    expensePayments: values(state.expensePayments.data),
    isLoading: state.expensePayments.isLoading,
    totalPages: state.expensePayments.totalPages,
    nextPage: state.expensePayments.next,
    previousPage: state.expensePayments.previous,
    currentPage: state.expensePayments.current,
    filters: state.filters.expenses,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    openAddExpensePaymentModal: () => dispatch(openModal(modalTypes.ADD_EXPENSE_PAYMENT)),
    openDeleteExpenseModal: () => dispatch(openModal(modalTypes.DELETE_EXPENSE_PAYMENT)),
    navigate: (route) => dispatch(push(route)),
    listExpensePayments: (entityID, search, filters, page) => dispatch(
        RESOURCES.expensePayments.list(
            {
                ...filters,
                entity_id: entityID,
                search: search,
                page: page,
                page_size: datatablePageSize
            },
            // overwriteData
            true
        )
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(ExpensePaymentsList)
