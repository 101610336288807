import React, {useEffect, useState} from 'react'
import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {Datatable} from '../../../../components/Datatable'
import {endOfMonth, lightFormat, startOfMonth} from 'date-fns'
import _, {isEmpty, isNull} from 'lodash'
import {durationAsHoursAndMinutes, toApiDateFormat} from '../../../../utils'
import TimeLogFilters from './TimeLogFilters'
import {Button} from '../../../../components/Button'
import {ArrowDownTrayIcon} from '@heroicons/react/24/solid'
import {TimeLogExportModal} from '../TimeLogExportModal'
import {modalTypes, openModal} from '../../../../redux/modals'
import {OpenModalColumn} from '../../../../components/OpenModalColumn'
import TimeLogBillableHoursModal from './TimeLogBillableHoursModal'

const timeLogListSize = 20

const TimeLogList = ({selectedEntityID, openModal, modalIsOpen}) => {
    const [generateCount, setGenerateCount] = useState(0)
    const [page, setPage] = useState(1)
    const [nextPage, setNextPage] = useState(null)
    const [previousPage, setPreviousPage] = useState(null)
    const [totalPages, setTotalPages] = useState(null)
    const [reports, setReports] = useState(null)

    const [startDate, setStartDate] = useState(startOfMonth(new Date()))
    const [stopDate, setStopDate] = useState(endOfMonth(new Date()))
    const [selectedClientID, setSelectedClientID] = useState(null)
    const [selectedProjectID, setSelectedProjectID] = useState(null)
    const [selectedContractID, setSelectedContractID] = useState(null)
    const [selectedUserID, setSelectedUserID] = useState(null)
    const [isTaskCompleted, setIsTaskCompleted] = useState(null)

    const [timeLogs, setTimeLogs] = useState([])
    const [editingTimeLog, setEditingTimLog] = useState()
    const [isLoadingTimeLogs, setIsLoadingTimeLogs] = useState(false)

    const fetchTimeLogs = async (params) => {
        setIsLoadingTimeLogs(true)
        const {data} = await performRequest(RESOURCES.taskTimeLogs.list({...params, page_size: timeLogListSize}))
        setIsLoadingTimeLogs(false)
        setNextPage(data.pages.next)
        setPreviousPage(data.pages.previous)
        setTotalPages(data.number_of_pages)

        return data.results
    }

    const fetchTimeLogsReports = async (params) => {
        const {data} = await performRequest(RESOURCES.taskTimeLogTotalsReports.list(params))
        setReports(data)

        return data
    }

    useEffect(() => {
        if (!generateCount) {
            return
        }
        if (modalIsOpen) {
            return
        }

        const dynamicFilters = {
            ...(selectedContractID && {contract_id: selectedContractID}),
            ...(selectedProjectID && {project_id: selectedProjectID}),
            ...(selectedClientID && {client_id: selectedClientID}),
            ...(selectedUserID && {owner_id: selectedUserID}),
            ...(!isNull(isTaskCompleted) && {is_task_completed: isTaskCompleted})
        }

        const filters = {
            entity_id: selectedEntityID,
            date__lte: toApiDateFormat(stopDate),
            date__gte: toApiDateFormat(startDate),
            page: page,
            ...dynamicFilters
        }
        fetchTimeLogs(filters).then((timeLogs) => {
            setTimeLogs(timeLogs)
        })
        fetchTimeLogsReports(filters).then((reports) => {
            setReports(reports)
        })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEntityID, page, generateCount, modalIsOpen])

    const columns = [
        {
            Header: 'Dată',
            accessor: 'date',
            Cell: ({value: date}) => lightFormat(new Date(date), 'dd/MM/yyyy')
        },
        {
            Header: 'Clienți',
            accessor: 'clients_names',
            Cell: ({value: clientsNames}) => _.join(clientsNames, ', ')
        },
        {
            Header: 'Proiect',
            accessor: 'project',
            Cell: ({value: project}) => !_.isNil(project) ? project.name : '-'
        },
        {
            Header: 'Avocat',
            accessor: 'owner',
            Cell: ({value: owner}) => owner.full_name
        },
        {
            Header: 'Nume Sarcină',
            accessor: 'task_title',
            Cell: ({row: {original: {task}}}) => !_.isNil(task) ? task.title : '-'
        },

        {
            Header: 'Ore lucrate',
            accessor: 'duration_as_hours',
            Cell: ({value: durationAsHours}) => durationAsHours ? durationAsHours : '-'
        },
        {
            Header: 'Ore facturabile',
            accessor: 'duration_billable_as_hours',
            Cell: ({value: duration_billable_as_hours, row: {original}}) => <OpenModalColumn
                value={duration_billable_as_hours}
                modalType={modalTypes.EDIT_TASK_TIME_LOG_BILLABLE_HOURS}
                onClick={() => setEditingTimLog(original)}
            />
        },
        {
            Header: 'Notă activitate',
            accessor: 'description',
            Cell: ({value: description}) => description || '-'
        },
        {
            Header: 'Solictant',
            accessor: 'task_requester',
            Cell: ({row: {original}}) => {
                const requester = _.get(original, ['task', 'requester'])

                return !_.isNil(requester) ?
                    `${requester.first_name} ${requester.last_name}` :
                    '-'
            }
        }
    ]

    const duration = reports?.duration ?
        durationAsHoursAndMinutes(reports?.duration) : '00:00'
    const durationAsHours = reports?.duration_as_hours || '0'

    const durationBillable = reports?.duration_billable ?
        durationAsHoursAndMinutes(reports?.duration_billable) : '00:00'
    const durationBillableAsHours = reports?.duration_billable_as_hours || '0'

    return (
        <div className="page-info">
            <TimeLogBillableHoursModal
                timeLogId={editingTimeLog?.id}
                defaultDurationBillableAsHours={editingTimeLog?.duration_billable_as_hours}
            />

            <TimeLogExportModal exportFilters={{
                ...(selectedContractID && {contract_id: selectedContractID}),
                ...(selectedProjectID && {project_id: selectedProjectID}),
                ...(selectedClientID && {client_id: selectedClientID}),
                ...(selectedUserID && {owner_id: selectedUserID}),
                ...(isTaskCompleted && {is_task_completed: isTaskCompleted}),
                date__lte: toApiDateFormat(stopDate),
                date__gte: toApiDateFormat(startDate)
            }}/>
            <div style={{marginBottom: '10px'}}
                className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4" role="alert">
                <p>În acest raport, se vor afișa doar datele la care ai acces,
                    conform drepturilor acordate de către administratorii contului tău.</p>
            </div>
            <TimeLogFilters
                onStartDateChange={setStartDate}
                onStopDateChange={setStopDate}
                onClientIDChange={setSelectedClientID}
                onProjectIDChange={setSelectedProjectID}
                onContractIDChange={setSelectedContractID}
                onUserIDChange={setSelectedUserID}
                onIsTaskCompletedChange={setIsTaskCompleted}
            />
            <div style={{marginBottom: '10px', display: 'inline-block'}}>
                <Button
                    color='secondary'
                    loading={isLoadingTimeLogs}
                    title='Generează Raport'
                    onClick={() => {
                        setGenerateCount(generateCount + 1)
                        setPage(1)
                    }}
                />
            </div>
            <span style={{margin: '0 10px 0 10px'}}>Ore lucrate: {duration} ({durationAsHours})</span>
            <span>Ore facturabile: {durationBillable} ({durationBillableAsHours})</span>
            <Datatable
                data={timeLogs}
                columns={columns}
                loading={isLoadingTimeLogs}
                nextPage={nextPage}
                previousPage={previousPage}
                currentPage={page}
                totalPages={totalPages}
                onChangePage={setPage}
                customHeader={() => (
                    <div className="buttons-container" style={{textAlign: 'right'}}>
                        <div style={{display: 'inline-block', marginRight: '10px'}}>
                            <Button
                                disabled={isEmpty(timeLogs)}
                                title='Descarcă'
                                icon={() => <ArrowDownTrayIcon/>}
                                onClick={() => openModal(modalTypes.EXPORT_TASK_TIME_LOG)}
                            />
                        </div>

                    </div>
                )}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    selectedEntityID: state.localConfigs.selectedEntityID,
    modalIsOpen: !!state.modals.type
})

const mapDispatchToProps = (dispatch) => ({
    openModal: (modalType) => dispatch(openModal(modalType))
})

export default connect(mapStateToProps, mapDispatchToProps)(TimeLogList)
