
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import {debounce, filter, includes, isNil, orderBy} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {removeFilter} from '../../../../redux/filters/filters'

import {columns} from './constants'
import {CANCELED_STATE, debounceWait} from '../../../../utils/constants'
import {datatablePageSize} from '../../../../utils/datatables'
import {generateFiltersForAPI} from '../../../../redux/filters/utils'

import {Button} from '../../../../components/Button'
import {Dropdown} from '../../../../components/Dropdown'
import {Datatable} from '../../../../components/Datatable'

import {ClientDetailsDisclaimer} from '../ClientDetailsDisclaimer'

import {FilterContractsForm} from './partials'

import './Contracts.scss'

export const Contracts = ({
    contracts,
    isLoading,
    previousPage,
    currentPage,
    nextPage,
    totalPages,
    filters,
    match: {params: {clientID}},
    removeFilter,
    listContracts
}) => {
    const [searchFilter, setSearchFilter] = useState('')

    const handleFetchContracts = (search = searchFilter, page = 1) => {
        const appliedFilters = generateFiltersForAPI(filters)

        listContracts(clientID, search, appliedFilters, page)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {handleFetchContracts()}, [])

    const debounceSearchContracts = debounce(handleFetchContracts, debounceWait)

    const handleChangeSearchField = (value) => {
        setSearchFilter(value)
        debounceSearchContracts(value)
    }

    const handleChangePage = (page) => !isLoading && handleFetchContracts(searchFilter, page)

    return (
        <div className='page-client-show client-contracts-list'>
            <ClientDetailsDisclaimer resource='contractele' />
            <Datatable
                title='Contracte'
                data={filter(contracts, (contract) => includes(contract.clients_ids, parseInt(clientID)))}
                columns={[
                    ...columns,
                    {
                        Header: 'Acțiuni',
                        accessor: 'id',
                        Cell: ({value: contractID, row: {original: contract}}) => (
                            <div className='flex gap-2 items-center'>
                                <Link to={`/contracts/${contractID}/preview`}>
                                    <Button title='Vizualizare' variant='text' size='small' />
                                </Link>
                                <Dropdown
                                    title='Descarcă'
                                    disabled={isNil(contract.last_version?.file) && isNil(contract.signed_file)}
                                    items={[
                                        {
                                            customContent: () => contract.last_version?.file ? (
                                                <a
                                                    href={contract.last_version.file}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className='avo-dropdown-menu-item'
                                                >
                                                    Descarcă
                                                </a>
                                            ) : <React.Fragment />
                                        },
                                        {
                                            customContent: () => contract.signed_file ? (
                                                <a
                                                    href={contract.signed_file}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className='avo-dropdown-menu-item'
                                                >
                                                    Descarcă fișier semnat
                                                </a>
                                            ) : <React.Fragment />
                                        }
                                    ]}
                                    variant='text'
                                    color='secondary'
                                    size='small'
                                />
                            </div>
                        ),
                        disableSortBy: true
                    }
                ]}
                loading={isLoading}
                previousPage={previousPage}
                currentPage={currentPage}
                nextPage={nextPage}
                totalPages={totalPages}
                onChangePage={(page) => handleChangePage(page)}
                filterable
                filters={filters}
                filtersForm={() => <FilterContractsForm filterContracts={handleFetchContracts} />}
                handleRemoveFilter={(filter) => {
                    removeFilter(filter)
                    handleFetchContracts()
                }}
                searchable
                searchValue={searchFilter}
                searchPlaceholder='Caută contracte'
                onSearch={(event) => handleChangeSearchField(event.target.value)}
                rowProps={row => ({
                    className: row.original.state === CANCELED_STATE ? 'strikeout-row' : null
                })}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    contracts: orderBy(
        state.contracts.data,
        ['signed_date', 'internal_series_name', 'internal_series_number'], ['desc', 'asc', 'desc']
    ),
    isLoading: state.contracts.isLoading,
    totalPages: state.contracts.totalPages,
    nextPage: state.contracts.next,
    previousPage: state.contracts.previous,
    currentPage: state.contracts.current,
    filters: state.filters.contracts
})

const mapDispatchToProps = (dispatch) => ({
    removeFilter: (filter) => dispatch(removeFilter(RESOURCES.contracts.name, filter)),
    listContracts: (clientID, search, filters, page) => dispatch(
        RESOURCES.contracts.list(
            {
                ...filters,
                client_id: clientID,
                search: search,
                page: page,
                page_size: datatablePageSize
            },
            // overwriteData
            true
        )
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(Contracts)