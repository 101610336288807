import React, {useEffect, useState} from 'react'

import {isEmpty, isNull, omitBy, values} from 'lodash'

import {getExpenseBalance} from 'avoapp-react-common/dist/redux/expenses'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {removeFilter} from '../../../redux/filters/filters'
import {generateFiltersForAPI} from '../../../redux/filters/utils'
import {openSlideover, slideoverTypes} from '../../../redux/slideovers'

import {datatablePageSize} from '../../../utils/datatables'

import {Button} from '../../../components/Button'
import {FilterTag} from '../../../components/Datatable/Filters'
import {Loader} from '../../../components/Loader'
import {Slideover} from '../../../components/Slideover'

import FilterExpensesForm from './ExpensesList/partials/forms/FilterExpensesForm'

const ExpenseBalance = ({
    selectedEntityID,
    openFiltersSlideover,
    filtersOpen,
    filters,
    listExpenses,
    listExpensePayments,
    removeFilter,
    balance,
    balanceLoading,
    getBalance,
    match: {params: {projectID, clientID}}
}) => {
    const getAppliedFilters = () => omitBy(filters, (filter) => isEmpty(filter.displayValue))
    const [appliedFilters, setAppliedFilters] = useState(getAppliedFilters())

    const handleFetchExpenses = () => {
        let appliedFilters = generateFiltersForAPI(filters)
        listExpenses(selectedEntityID, null, appliedFilters, 1)
    }
    const handleFetchExpensePayments = () => {
        let appliedFilters = generateFiltersForAPI(filters)
        listExpensePayments(selectedEntityID, null, appliedFilters, 1)
    }

    useEffect(() => {
        let params = {
            entity_id: selectedEntityID,
            ...generateFiltersForAPI(appliedFilters)
        }

        if(projectID) {
            params.project_id = projectID
        }

        if(clientID) {
            params.client_id = clientID
        }

        getBalance(params)
    }, [clientID, getBalance, projectID, selectedEntityID, appliedFilters])

    return (
        <div >
            <div style={{marginBottom: '15px'}}>
                <div style={{display: 'inline-block'}}>
                    {!isNull(balance) && (
                        <p className="card-title">
                            Încasări - cheltuieli = {balance} RON.
                        </p>
                    )}
                    {balanceLoading && <Loader />}
                </div>
            </div>
            <div
                className="avo-filters-container"
                style={{display: 'inline-block', marginRight: '10px', marginBottom: '10px'}}
            >
                <Button title='Filtre' onClick={openFiltersSlideover} />
                <Slideover title='Filtre' open={filtersOpen}>
                    <FilterExpensesForm
                        clientID={clientID}
                        projectID={projectID}
                        filterExpenses={() => {
                            handleFetchExpenses()
                            handleFetchExpensePayments()
                            setAppliedFilters(getAppliedFilters())
                        }}
                    />
                </Slideover>
            </div>
            {!isEmpty(appliedFilters) && (
                <div className="filters-container" style={{display: 'inline-block'}}>
                    <div style={{display: 'inline-block'}}>
                        <p style={{marginRight: '5px'}} className='filters-text'>Filtre aplicate: </p>
                    </div>
                    <div className="filters-list" style={{display: 'inline-block'}}>
                        {values(appliedFilters).map((filter, filterIdx) => (
                            <FilterTag
                                filter={filter}
                                onRemove={() => {
                                    removeFilter(filter)
                                    handleFetchExpenses()
                                    handleFetchExpensePayments()
                                    setAppliedFilters(getAppliedFilters())
                                }}
                                key={filter.key}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    selectedEntityID: state.localConfigs.selectedEntityID,
    filtersOpen: state.slideovers.type === slideoverTypes.DATATABLE_FILTERS,
    filters: state.filters.expenses,
    balance: state.expenses.balance,
    balanceLoading: state.expenses.balanceLoading
})

const mapDispatchToProps = dispatch => ({
    openFiltersSlideover: () => dispatch(openSlideover(slideoverTypes.DATATABLE_FILTERS)),
    removeFilter: (filter) => dispatch(removeFilter(RESOURCES.expenses.name, filter)),
    listExpenses: (entityID, search, filters, page) => dispatch(
        RESOURCES.expenses.list(
            {
                ...filters,
                entity_id: entityID,
                search: search,
                page: page,
                page_size: datatablePageSize
            },
            // overwriteData
            true
        )
    ),
    listExpensePayments: (entityID, search, filters, page) => dispatch(
        RESOURCES.expensePayments.list(
            {
                ...filters,
                entity_id: entityID,
                search: search,
                page: page,
                page_size: datatablePageSize
            },
            // overwriteData
            true
        )
    ),
    getBalance: (params) => dispatch(getExpenseBalance(params))
})

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseBalance)
