import React from 'react'
import {DocumentEditor} from '@onlyoffice/document-editor-react'
import './DocumentViewer.scss'
import {ONLY_OFFICE_ALLOWED_EXTENSIONS} from './constants'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {performRequest} from 'avoapp-react-common/dist/redux/api'

const DocumentViewer = ({fileMeta, user, editable = false}) => {
    return <>{
        ONLY_OFFICE_ALLOWED_EXTENSIONS.includes(fileMeta.extension) ? <DocumentEditor
            id='docEditor'
            documentServerUrl={process.env.REACT_APP_ONLYOFFICE_API_URL}
            config={{
                document: {
                    fileType: fileMeta.extension,
                    key: fileMeta.only_office_key,
                    title: fileMeta.name,
                    url: fileMeta.url_public,
                    permissions: {
                        download: false,
                        modifyFilter: false,
                        chat: false,
                        review: false,
                        fillForms: false,
                        comment: false,
                        copy: false,
                        protect: false
                    }
                },
                documentType: 'word',
                editorConfig: {
                    mode: editable ? 'edit' : 'view',
                    callbackUrl: `${process.env.REACT_APP_API_URL}onlyoffice/callback/`,
                    lang: 'ro',
                    user: {
                        id: user.id.toString(),
                        name: user.full_name
                    },
                    customization: {
                        logo: {
                            // Work around to remove the logo from editor
                            image: 'https://app.avoapp.ro/logo-avoapp.svg',
                            url: null
                        },
                        customer: {
                            name: 'AvoApp'
                        },
                        zoom: -2,
                        hideRightMenu: true,
                        compactHeader: true,
                        uiTheme: 'avoapp-theme',
                        autosave: false,
                        forcesave: true
                    }
                },
                events: {
                    onWarning: async (event) => {
                        const {data} = await performRequest(RESOURCES.files.retrieve(fileMeta.id))
                        window.DocEditor?.instances.docEditor.showMessage(data?.error_message)
                    }
                },
                token: fileMeta.only_office_token
            }}
        /> : <embed
            src={`${fileMeta.url_public}#view=fitH`}
            title={fileMeta.name}
            height="100%"
            width="100%"
        />}
    </>
}

export default DocumentViewer
