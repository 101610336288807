import React from 'react'
import {PencilSquareIcon} from '@heroicons/react/24/outline'
import './OpenModalColumn.scss'
// @ts-ignore
import {modalTypes, openModal} from '../../redux/modals'
import {connect} from 'react-redux'

interface EditableColumnProps {
    value: number | string
    modalType: modalTypes
    openModal: (modalType: modalTypes) => void
    onClick: () => any
}

const OpenModalColumn: React.FC<EditableColumnProps> = ({value, modalType, onClick, openModal}) => {
    return <div className="open-modal-column">
        <div>{value}</div>
        <div
            onClick={() => {
                onClick()
                openModal(modalType)
            }}
            className="icon"
        >
            <PencilSquareIcon/>
        </div>
    </div>
}

const mapDispatchToProps = (dispatch: any) => ({
    openModal: (modalType: modalTypes) => dispatch(openModal(modalType))
})

export default connect(undefined, mapDispatchToProps)(OpenModalColumn)
