import React, {Component} from 'react'

import {debounce, find, isNil, values} from 'lodash'

import {setCurrent, updateDocumentTemplate} from 'avoapp-react-common/dist/redux/documentTemplates'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {removeFilter} from '../../../../redux/filters/filters'
import {generateFiltersForAPI} from '../../../../redux/filters/utils'
import {modalTypes, openModal} from '../../../../redux/modals'

import {UploadTemplateFileButton} from './partials'
import {AddDocumentTemplateModal, DeleteDocumentTemplateModal} from './partials/modals'

import {getFieldOptions} from '../../../../utils'
import {debounceWait} from '../../../../utils/constants'
import {datatablePageSize} from '../../../../utils/datatables'

import {Button} from '../../../../components/Button'
import {Datatable} from '../../../../components/Datatable'

import {FilterDocumentTemplatesForm} from './partials'

import './DocumentTemplates.scss'
import {getTokenAndUploadFile} from '../../../../utils/files'

export class DocumentTemplates extends Component {
    constructor() {
        super()

        this.state = {
            searchFilter: ''
        }
    }

    componentDidMount() {
        const {getDocumentTemplateOptions} = this.props

        getDocumentTemplateOptions()
        this.handleFetchDocumentTemplates()
    }

    componentDidUpdate(prevProps, prevState) {
        const {primaryAction} = this.props.match.params

        if(prevProps.match.params.primaryAction !== primaryAction) {
            this.handleFetchDocumentTemplates()
        }
    };

    handleFetchDocumentTemplates = (page = 1) => {
        const {searchFilter} = this.state
        const {selectedEntityID, listDocumentTemplates, filters} = this.props

        let appliedFilters = generateFiltersForAPI(filters)

        listDocumentTemplates(selectedEntityID, searchFilter, appliedFilters, page)
    }

    debounceSearchDocumentTemplates = debounce(() => this.handleFetchDocumentTemplates(), debounceWait)

    handleChangeSearchField = (value) => {
        this.setState({searchFilter: value})
        this.debounceSearchDocumentTemplates()
    }

    handleChangePage = (page) => {
        const {isLoading} = this.props

        if(!isLoading) {
            this.handleFetchDocumentTemplates(page)
        }
    }

    handleDrop = (selectedFile, documentTemplateID) => {
        const {updateDocumentTemplate, selectedEntityID} = this.props

        getTokenAndUploadFile(selectedFile, selectedEntityID).then((fileURL) => {
            updateDocumentTemplate({file: fileURL}, documentTemplateID)
        })
    }

    handleOpenDeleteDocumentTemplateModal = (documentTemplate) => {
        const {openModal, setCurrentDocumentTemplate} = this.props

        setCurrentDocumentTemplate(documentTemplate)
        openModal(modalTypes.DELETE_DOCUMENT_TEMPLATE)
    }

    getDocumentTemplatesColumns = () => {
        const {documentTemplateOptions} = this.props
        const documentTemplateTypes = getFieldOptions(documentTemplateOptions, 'type')

        return [
            {
                Header: 'Nume',
                accessor: 'name'
            },
            {
                Header: 'Tip',
                accessor: 'type',
                Cell: ({value: type}) => {
                    const localType = find(documentTemplateTypes, ['value', type])
                    return !isNil(localType) ? localType.label : '-'
                }
            },
            {
                Header: '',
                accessor: 'id',
                Cell: ({row: {original: documentTemplate}}) => (
                    <div className="flex gap-2 items-center">
                        <a
                            href={`settings/documents/templates/${documentTemplate.id}/edit`}
                            rel="noopener noreferrer"
                        >
                            <Button title="Editează" variant="text" size="small" color="primary"/>
                        </a>
                        <a href={documentTemplate.file_meta?.url_public} target="_blank" rel="noopener noreferrer">
                            <Button title="Descarcă" variant="text" size="small" color="secondary"/>
                        </a>
                        <UploadTemplateFileButton
                            onChange={(selectedFile) => {
                                this.handleDrop(selectedFile, documentTemplate.id)
                            }}
                        />
                        <Button
                            title="Șterge"
                            variant="text"
                            onClick={() => this.handleOpenDeleteDocumentTemplateModal(documentTemplate)}
                            size="small"
                            color="red"
                        />
                    </div>
                )
            }
        ]
    }

    render() {
        const {searchFilter} = this.state
        const {
            documentTemplates,
            isLoading,
            totalPages,
            nextPage,
            previousPage,
            currentPage,
            filters,
            removeFilter,
            openModal
        } = this.props

        const documentTemplatesColumns = this.getDocumentTemplatesColumns()

        return (
            <div className="document-template-settings">
                <AddDocumentTemplateModal />
                <DeleteDocumentTemplateModal />
                <Datatable
                    title="Listă șabloane"
                    data={documentTemplates}
                    columns={documentTemplatesColumns}
                    headerButton={() =>
                        <Button
                            title='Adaugă șabloane'
                            onClick={() => openModal(modalTypes.ADD_DOCUMENT_TEMPLATE)}
                            color='secondary'
                        />
                    }
                    loading={isLoading}
                    totalPages={totalPages}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    currentPage={currentPage}
                    onChangePage={(page) => this.handleChangePage(page)}
                    filterable
                    filters={filters}
                    filtersForm={() =>
                        <FilterDocumentTemplatesForm filterDocumentTemplates={this.handleFetchDocumentTemplates} />
                    }
                    handleRemoveFilter={(filter) => {
                        removeFilter(filter)
                        this.handleFetchDocumentTemplates()
                    }}
                    searchable
                    searchValue={searchFilter}
                    searchPlaceholder="Caută șabloane"
                    onSearch={(event) => this.handleChangeSearchField(event.target.value)}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    documentTemplates: values(state.documentTemplates.data),
    isLoading: state.documentTemplates.isLoading,
    totaldocumentTemplates: state.documentTemplates.count,
    totalPages: state.documentTemplates.totalPages,
    nextPage: state.documentTemplates.next,
    previousPage: state.documentTemplates.previous,
    currentPage: state.documentTemplates.current,
    selectedEntityID: state.localConfigs.selectedEntityID,
    entityProfileID:  state.localConfigs.entityProfileID,
    filters: state.filters.documentTemplates,
    documentTemplateOptions: state.documentTemplates.options
})

const mapDispatchToProps = (dispatch) => ({
    openModal: (modalType) => dispatch(openModal(modalType)),
    removeFilter: (filter) => dispatch(removeFilter(RESOURCES.documentTemplates.name, filter)),
    updateDocumentTemplate: (documentData, documentTemplateID) => dispatch(
        updateDocumentTemplate(documentData, documentTemplateID)
    ),
    getDocumentTemplateOptions: () => dispatch(RESOURCES.documentTemplates.getOptions()),
    setCurrentDocumentTemplate: (documentTemplate) => dispatch(setCurrent(documentTemplate)),
    listDocumentTemplates: (entity_id, search, filters, page) => dispatch(
        RESOURCES.documentTemplates.list(
            {
                ...filters,
                entity_id: entity_id,
                search: search,
                page: page,
                page_size: datatablePageSize
            },
            // overwriteData
            true
        )
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(DocumentTemplates)