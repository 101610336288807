import React, {useCallback, useEffect, useMemo, useState} from 'react'

import _ from 'lodash'
import {toast} from 'react-toastify'

import {connect} from 'react-redux'
// @ts-ignore
import {performRequest} from 'avoapp-react-common/dist/redux/api'
// @ts-ignore
import {RESOURCES, RESOURCES_V2} from 'avoapp-react-common/dist/redux/spec'
// @ts-ignore
import {getCompaniesFromName} from 'avoapp-react-common/dist/redux/companies'
// @ts-ignore
import {closeModal, openModal, modalTypes} from '../../redux/modals'

// @ts-ignore
import {bpiAddTabs} from '../../pages/ProjectDetails/partials/ExternalData/constants'

import {Tabs} from '../Tabs'
// @ts-ignore
import {Modal} from '../Modal'
// @ts-ignore
import {ErrorComponent} from '../ErrorComponents/ErrorsList'
// @ts-ignore
import {Select} from '../Select'

import CompaniesList from './CompaniesList'
import SearchDataSourcesForm from './SearchDataSourcesForm'

import './AddBPISubscriptionModal.scss'
import {ProjectMinimal} from '../../types/api'
import {useQueryResourceSearch} from '../../queries/rest'
import {BPISubscriptionInformationModal} from '../BPISubscriptionInformationModal'

interface AddBPISubscriptionModalProps {
    project?: ProjectMinimal
    open: boolean
    closeModal: () => void
    openModal: (modalType: string) => void
    selectedEntityID: number
}

export const AddBPISubscriptionModal = ({
    open,
    closeModal,
    openModal,
    project,
    selectedEntityID
}: AddBPISubscriptionModalProps) => {
    const [selectedTab, setSelectedTab] = useState<string>(bpiAddTabs.WITH_VAT_CODE)
    const [companiesResult, setCompaniesResult] = useState<any>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [selectedProject, setSelectedProject] = useState<ProjectMinimal | undefined>(project)

    const isNameFormSelected = useMemo(() => {
        return selectedTab === bpiAddTabs.WITH_NAME
    }, [selectedTab])

    const {data: projects, isFetching: isFetchingProjects} = useQueryResourceSearch(
        RESOURCES_V2.projects,
        '',
        {entity_id: selectedEntityID},
        {enabled: !project}
    )

    useEffect(() => {
        if(!open) {
            setCompaniesResult(null)
        } else if (project) {
            setSelectedProject(project)
        }
    }, [open, project])

    const handleChangeTab = useCallback((tab) => setSelectedTab(tab), [])

    const onAddSubscription = useCallback(({vatNumber, companyName}) => {
        setIsLoading(true)
        performRequest(
            RESOURCES.bpiSubscriptions.create({
                project_id: selectedProject?.id,
                vat_number: vatNumber,
                company_name: companyName
            })
        ).then((res: any) => {
            setIsLoading(false)
            if(!res.errors) {
                toast.success('Salvat')
                closeModal()
                openModal(modalTypes.BPI_SUBSCRIPTION_INFORMATION)
            } else {
                _.forEach(_.values(res.errors), (error) => {
                    if(Array.isArray(error)) {
                        _.forEach(error, (err) => {
                            toast.error(err.message)
                        })
                    } else {
                        toast.error(error.message)
                    }
                })
            }
        })
    }, [closeModal, openModal, selectedProject])

    const onSearchCompany = useCallback(({companyName}) => {
        setIsLoading(true)
        performRequest(getCompaniesFromName(companyName)).then((res: any) => {
            setIsLoading(false)
            setCompaniesResult(res)
        })
    }, [])

    const hasErrors = useMemo(() => {
        return !_.isNil(companiesResult) &&
        _.isEmpty(companiesResult.data) &&
        !_.isEmpty(companiesResult.errors?.non_field_errors)
    }, [companiesResult])

    return (
        <>
            <Modal open={open} title='Adaugă abondare Monitor BPI' maxWidth='80%' minWidth='1000px'>
                <div className="add-bpi-subscription-modal-content">
                    <div className="search-data-sources-container">
                        <p className="section-title">Caută surse de date</p>
                        <Tabs
                            tabs={_.values(bpiAddTabs)}
                            selectedTab={selectedTab}
                            onChangeTab={handleChangeTab}
                        />

                        {!project && (
                        <div className="project-select">
                            <Select
                                label='Proiect*'
                                value={selectedProject}
                                onChange={setSelectedProject}
                                options={projects}
                                disabled={isFetchingProjects}
                                loading={isFetchingProjects}
                                getOptionValue={(option: ProjectMinimal) => option.id}
                                getOptionLabel={(option: ProjectMinimal) => option.name}
                                fullWidth
                            />
                        </div>
                        )}
                        <SearchDataSourcesForm
                            selectedTab={selectedTab}
                            onAddSubscription={onAddSubscription}
                            onSearchCompany={onSearchCompany}
                            isLoading={isLoading}
                            isDisabled={!selectedProject}
                        />
                    </div>

                    {isNameFormSelected && (
                    <div className="results-list-container">
                        <p className="section-title">Alege o companie</p>
                        {hasErrors ? (
                            <ErrorComponent message={companiesResult.errors.non_field_errors.message} />
                        ) : (
                            <>
                                {_.isNil(companiesResult) && (
                                    <p className='companies-list-empty'>Aici vor aparea rezultatele in urma cautarii</p>
                                )}

                                {!_.isNil(companiesResult) && _.isEmpty(companiesResult?.data?.results) && (
                                    <p className='companies-list-empty'>Nu s-au gasit companii cu numele acesta</p>
                                )}

                                {!_.isNil(companiesResult) && !_.isEmpty(companiesResult?.data?.results) && (
                                    <CompaniesList
                                        companies={companiesResult?.data?.results}
                                        onAddSubscription={onAddSubscription}
                                    />
                                )}
                            </>
                        )}
                    </div>
                    )}
                </div>
            </Modal>
            <BPISubscriptionInformationModal />
        </>
    )
}

const mapStateToProps = (state: any) => ({
    open: state.modals.type === modalTypes.ADD_MONITOR_BPI_SUBSCRIPTION,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch: any) => ({
    closeModal: () => dispatch(closeModal()),
    openModal: (modalType: string) => dispatch(openModal(modalType))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddBPISubscriptionModal)
