
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {PlusIcon} from '@heroicons/react/24/solid'

import {isEmpty, debounce, filter, includes, isNil, orderBy} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {uploadSignedFile} from 'avoapp-react-common/dist/redux/contracts'
import {modalTypes, openModal} from '../../../../redux/modals'
import {removeFilter} from '../../../../redux/filters/filters'

import {columns} from './constants'
import {CANCELED_STATE, debounceWait, ISSUED_STATE} from '../../../../utils/constants'
import {datatablePageSize} from '../../../../utils/datatables'
import {generateFiltersForAPI} from '../../../../redux/filters/utils'

import {Button} from '../../../../components/Button'
import {Dropdown} from '../../../../components/Dropdown'
import {Datatable} from '../../../../components/Datatable'
import {SignedFileDropzone} from '../../../../components/SignedFileDropzone'

import {
    // AssignContractModal,
    FilterContractsForm,
    InvalidateContractModal,
    RemoveOrDeleteContractModal
} from './partials'

import './Contracts.scss'
import {getTokenAndUploadFile} from '../../../../utils/files'
import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {toast} from 'react-toastify'

export const Contracts = ({
    contracts,
    isLoading,
    previousPage,
    currentPage,
    nextPage,
    totalPages,
    filters,
    match: {params: {projectID}},
    openModal,
    removeFilter,
    updateContract,
    listContracts,
    selectedEntityID
}) => {
    const [selectedContractID, setSelectedContractID] = useState('')
    const [searchFilter, setSearchFilter] = useState('')

    const handleFetchContracts = (search = searchFilter, page = 1) => {
        const appliedFilters = generateFiltersForAPI(filters)

        listContracts(projectID, search, appliedFilters, page)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {handleFetchContracts()}, [])

    const debounceSearchContracts = debounce(handleFetchContracts, debounceWait)

    const handleChangeSearchField = (value) => {
        setSearchFilter(value)
        debounceSearchContracts(value)
    }

    const handleChangePage = (page) => !isLoading && handleFetchContracts(searchFilter, page)

    const handleDropSignedFile = async (selectedFile, contractID) => {
        if(!isEmpty(selectedFile)) {
            const fileURL = await getTokenAndUploadFile(selectedFile, selectedEntityID)
            await performRequest(RESOURCES.contracts.update({signed_file: fileURL}, contractID))
            toast.success('Documentul semnat a fost încărcat cu succes.')
        }
    }

    const handleActivateContract = (contractID) => updateContract({state: ISSUED_STATE}, contractID)

    return (
        <div className='page-project-show project-contracts-list'>
            {/*<AssignContractModal projectId={projectID} />*/}
            <RemoveOrDeleteContractModal contractID={selectedContractID} />
            <InvalidateContractModal contractID={selectedContractID} />
            <Datatable
                title='Contracte'
                data={filter(contracts, (contract) => includes(contract.projects_ids, parseInt(projectID)))}
                headerButton={() => (
                    <div className='contracts-datatable-buttons'>
                        {/* <Button
                            title='Assign contract'
                            onClick={() => openModal(modalTypes.ASSIGN_CONTRACT)}
                            icon={() => <LinkIcon />}
                            color='secondary'
                        /> */}
                        <Link to={`/contracts/add?initialProjectId=${projectID}`}>
                            <Button title='Adaugă contract' icon={() => <PlusIcon />} />
                        </Link>
                    </div>
                )}
                columns={[
                    ...columns,
                    {
                        Header: 'Acțiuni',
                        accessor: 'id',
                        Cell: ({value: contractID, row: {original: contract}}) => (
                            <div className='flex gap-2 items-center'>
                                <Link to={`/contracts/${contractID}/preview`}>
                                    <Button title='Vizualizare' variant='text' size='small' />
                                </Link>
                                <Dropdown
                                    title='Descarcă'
                                    disabled={isNil(contract.file) && isNil(contract.signed_file)}
                                    items={[
                                        {
                                            customContent: () => contract.file ? (
                                                <a
                                                    href={contract.file}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className='avo-dropdown-menu-item'
                                                >
                                                    Descarcă
                                                </a>
                                            ) : <React.Fragment />
                                        },
                                        {
                                            customContent: () => contract.signed_file ? (
                                                <a
                                                    href={contract.signed_file}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className='avo-dropdown-menu-item'
                                                >
                                                    Descarcă fișier semnat
                                                </a>
                                            ) : <React.Fragment />
                                        }
                                    ]}
                                    variant='text'
                                    color='secondary'
                                    size='small'
                                />
                                <Dropdown
                                    title='Încarcă'
                                    items={[
                                        {
                                            customContent: () => (
                                                <SignedFileDropzone
                                                    onChange={(selectedFile) => (
                                                        handleDropSignedFile(selectedFile, contractID)
                                                    )}
                                                />
                                            )
                                        }
                                    ]}
                                    variant='text'
                                    color='orange'
                                    size='small'
                                />
                                {!contract.deletion_prevented ? (
                                    <Button
                                        title='Șterge'
                                        onClick={() => {
                                            setSelectedContractID(contractID)
                                            openModal(modalTypes.REMOVE_OR_DELETE_CONTRACT)
                                        }}
                                        variant='text'
                                        size='small'
                                        color='red'
                                    />
                                ) : (
                                    <>
                                        {contract.state === CANCELED_STATE ? (
                                            <Button
                                                title='Activează'
                                                onClick={() => handleActivateContract(contractID)}
                                                variant='text'
                                                color='gray'
                                                size='small'
                                            />
                                        ) : (
                                            <Button
                                                title='Anulează'
                                                onClick={() => {
                                                    setSelectedContractID(contractID)
                                                    openModal(modalTypes.INVALIDATE_CONTRACT)
                                                }}
                                                variant='text'
                                                size='small'
                                                color='red'
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                        ),
                        disableSortBy: true
                    }
                ]}
                loading={isLoading}
                previousPage={previousPage}
                currentPage={currentPage}
                nextPage={nextPage}
                totalPages={totalPages}
                onChangePage={(page) => handleChangePage(page)}
                filterable
                filters={filters}
                filtersForm={() => <FilterContractsForm filterContracts={handleFetchContracts} />}
                handleRemoveFilter={(filter) => {
                    removeFilter(filter)
                    handleFetchContracts()
                }}
                searchable
                searchValue={searchFilter}
                searchPlaceholder='Caută contracte'
                onSearch={(event) => handleChangeSearchField(event.target.value)}
                rowProps={row => ({
                    className: row.original.state === CANCELED_STATE ? 'strikeout-row' : null
                })}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    selectedEntityID: state.localConfigs.selectedEntityID,
    contracts: orderBy(
        state.contracts.data,
        ['signed_date', 'internal_series_name', 'internal_series_number'], ['desc', 'asc', 'desc']
    ),
    isLoading: state.contracts.isLoading,
    totalPages: state.contracts.totalPages,
    nextPage: state.contracts.next,
    previousPage: state.contracts.previous,
    currentPage: state.contracts.current,
    filters: state.filters.contracts
})

const mapDispatchToProps = (dispatch) => ({
    openModal: (modalType) => dispatch(openModal(modalType)),
    removeFilter: (filter) => dispatch(removeFilter(RESOURCES.contracts.name, filter)),
    uploadSignedFile: (contractData, contractID) => dispatch(uploadSignedFile(contractData, contractID)),
    updateContract: (contractData, contractID) => dispatch(RESOURCES.contracts.update(contractData, contractID)),
    listContracts: (projectID, search, filters, page) => dispatch(
        RESOURCES.contracts.list(
            {
                ...filters,
                project_id: projectID,
                search: search,
                page: page,
                page_size: datatablePageSize
            },
            // overwriteData
            true
        )
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(Contracts)