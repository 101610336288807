import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'

import {PencilIcon, TrashIcon} from '@heroicons/react/24/outline'

import {lightFormat} from 'date-fns'
import _ from 'lodash'

import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {modalTypes, openModal} from '../../../../redux/modals'

import {useInfiniteQuery} from '@tanstack/react-query'

import {Button} from '../../../../components/Button'
import {Loader} from '../../../../components/Loader'

import AddHearingModal from './AddHearingModal'
import DeleteHearingModal from './DeleteHearingModal'
import EditHearingModal from './EditHearingModal'

import {toApiDateFormat} from '../../../../utils'
import './HearingsSection.scss'
import {Ordering} from '../../../../utils/constants.ts'
import {Link} from 'react-router-dom'

const orderingMapping = {
    [Ordering.ASC]: 'moment',
    [Ordering.DESC]: '-moment'
}

const HearingsSection = ({
    projectID,
    subscription,
    isManualSubscription,
    ordering = Ordering.DESC,
    showSubscriptionNumber = false,
    sectionTitle,
    timeInterval,
    selectedEntityID,
    openedModal,
    openModal
}) => {
    const [selectedHearing, setSelectedHearing] = useState(undefined)

    const loaderRef = useRef(null)

    const filters = useMemo(() => {
        let initialFilters = {
            entity_id: selectedEntityID
        }

        if(projectID) {
            initialFilters = {
                ...initialFilters,
                project_id: projectID

            }
        }

        if(subscription?.id) {
            initialFilters = {
                ...initialFilters,
                subscription_id: subscription?.id
            }
        }

        if(timeInterval?.start) {
            initialFilters = {
                ...initialFilters,
                moment__gte: toApiDateFormat(new Date(timeInterval.start))
            }
        }

        if(timeInterval?.stop) {
            initialFilters = {
                ...initialFilters,
                moment__lte: toApiDateFormat(new Date(timeInterval.stop))
            }
        }

        return initialFilters

    }, [projectID, subscription?.id, timeInterval, selectedEntityID])

    const orderValue = _.get(orderingMapping, ordering, '-moment')

    const fetchHearings = useCallback(async ({pageParam = 1}) => {
        return performRequest(RESOURCES.hearings.list({...filters, page: pageParam, ordering: orderValue}))
    }, [filters, orderValue])

    const {data: hearingsResult, isFetching, fetchNextPage, hasNextPage} = useInfiniteQuery({
        queryKey:[
            RESOURCES.hearings,
            filters,
            //we want to refetch the data when the add or delete modal closes
            _.isNil(openedModal)
        ],
        queryFn: fetchHearings,
        getNextPageParam: (response) => response?.data?.pages?.next
    })

    const handleFetchNextPage = useCallback((params) => {
        if(hasNextPage) {
            fetchNextPage()
        }
    }, [fetchNextPage, hasNextPage])

    const orderedHearingsData = useMemo(() => {
        if(_.isNil(hearingsResult)) {
            return []
        }

        return hearingsResult.pages.flatMap((page) => page.data.results)
    }, [hearingsResult])

    const handleEditHearing = useCallback((hearing) => {
        setSelectedHearing(hearing)
        openModal(modalTypes.EDIT_HEARING)
    }, [openModal])

    const handleDeleteHearing = useCallback((hearing) => {
        setSelectedHearing(hearing)
        openModal(modalTypes.DELETE_HEARING)
    }, [openModal])

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const target = entries[0]
            if (target.isIntersecting) {
                handleFetchNextPage()
            }
        })

        if (loaderRef.current) {
            observer.observe(loaderRef.current)
        }

        return () => {
            if (loaderRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                observer.unobserve(loaderRef.current)
            }
        }
    }, [handleFetchNextPage])

    return (
        <div className="hearings-container">
            <div className="section-title-container">
                <p className="section-title">{sectionTitle || 'Termene'}</p>
                {isManualSubscription && (
                    <>
                        <Button
                            title='Adaugă termene'
                            onClick={() => openModal(modalTypes.ADD_HEARING_MODAL)}
                            color='secondary'
                        />
                        <AddHearingModal subscription={subscription} />
                    </>
                )}
            </div>
            <div className='hearings-list-container'>
                {orderedHearingsData && orderedHearingsData.length >0 ? (
                    <ul className="space-y-6">
                        {orderedHearingsData.map((hearing) => (
                            <HearingsInfoItem
                                key={hearing.id}
                                hearing={hearing}
                                showSubscriptionNumber={showSubscriptionNumber}
                                isManualSubscription={isManualSubscription}
                                onEdit={() => handleEditHearing(hearing)}
                                onDelete={() => handleDeleteHearing(hearing)}
                                includeDetailsLink={
                                    !projectID
                                    && _.isNil(subscription)
                                    && !_.isNil(hearing.project)
                                    && !_.isNil(hearing.related_subscription_id)
                                }
                            />
                        ))}
                        <div ref={loaderRef}>
                            {isFetching && (
                                <div className="center-loader">
                                    <Loader />
                                </div>
                            )}
                        </div>
                    </ul>
                ) : !isFetching && (
                    <p>Nu există niciun termen</p>
                )}
            </div>
            <DeleteHearingModal hearing={selectedHearing} />
            <EditHearingModal hearing={selectedHearing} />
        </div>
    )
}

function HearingsInfoItem(
    {hearing, isManualSubscription, onEdit, onDelete, showSubscriptionNumber, includeDetailsLink}
) {
    return (
        <li className="hearing-info-item-container" key={hearing.unique_id}>
            <div className='hearing-info-item-line-container'>
                <div className="hearing-info-item-line" />
            </div>
            <div className="hearing-info-item-dot-container">
                <div className="hearing-info-item-dot" />
            </div>
            <div className="hearing-details-container">
                {
                    showSubscriptionNumber && <Link to={
                        `/projects/${hearing.project?.id}/external-data`
                        + `/monitor-dosare/${hearing.related_subscription_id}`
                    } style={!includeDetailsLink ? {pointerEvents: 'none'} : null}>
                        <p className="text-primary font-bold">{hearing.phase.number}</p>
                    </Link>
                }
                <div className="hearing-details-header">
                    <div className="text-sm">
                        {!_.isNil(hearing.moment) && (
                            <p className="hearing-time">
                                {lightFormat(new Date(hearing.moment), 'dd/MM/yyyy')}
                                {' - '}
                                {lightFormat(new Date(hearing.moment), 'HH:mm')}
                            </p>
                        )}
                        <p className="hearing-court">
                            {hearing.phase?.court} - {hearing.full_court}
                        </p>
                    </div>
                    <div className="header-details-right-container">
                        {!_.isNil(hearing.phase?.phase) && (
                            <p className="hearing-phase">
                                {hearing.phase.phase}
                            </p>
                        )}
                        {isManualSubscription && (
                            <div className="header-edit-delete-container">
                                <Button
                                    icon={() => <PencilIcon/>}
                                    onClick={onEdit}
                                    variant="outlined"
                                    size="small"
                                />
                                <Button
                                    icon={() => <TrashIcon/>}
                                    onClick={onDelete}
                                    size="small"
                                    color="red"
                                />
                            </div>
                        )}
                    </div>
                </div>

                <div className="hearing-details-section">
                    {!_.isNil(hearing.solution) && (
                        <p className="hearing-detail-key">
                            <span className="font-bold">Soluție:</span> {hearing.solution}
                        </p>
                    )}

                    {!_.isNil(hearing.solution_summary) && (
                        <p className="hearing-detail-key">
                            <span className="font-bold">Sumar soluție: </span><br/>
                            {hearing.solution_summary}
                        </p>
                    )}
                </div>

                <div className="hearing-details-section">
                    {!_.isNil(hearing.document_date) && (
                        <p className="hearing-detail-key">
                            <span className="font-bold">Data document:</span>{' '}
                            {lightFormat(new Date(hearing.document_date), 'dd/MM/yyyy')}
                        </p>
                    )}

                    {!_.isNil(hearing.hearing_document) && (
                        <p className="hearing-detail-key">
                            <span className="font-bold">Document ședință:</span> {hearing.hearing_document}
                        </p>
                    )}
                </div>
                {includeDetailsLink && (
                    <div className="hearing-detail-footer">
                        <Link to={
                            `/projects/${hearing.project.id}/external-data`
                            + `/monitor-dosare/${hearing.related_subscription_id}`
                        }>
                            <Button title='vezi detalii termen' variant='text' size='small' />
                        </Link>
                    </div>
                )}
            </div>
        </li>
    )
}

const mapStateToProps = (state) => ({
    openedModal: state.modals.type,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    openModal: (modalType) => dispatch(openModal(modalType))
})

export default connect(mapStateToProps, mapDispatchToProps)(HearingsSection)
