import React, {useCallback, useMemo} from 'react'
import {Redirect} from 'react-router-dom'

import Notifications from 'pages/Notifications/Notifications'

// @ts-ignore
import {SecondaryNavbar} from '../../components/SecondaryNavbar'

import {actionIds, actions, prefix} from './constants'
import {MonitorBpiDatatable, MonitorDosareDatatable, MonitorDosareHearings} from './partials'

import './ExternalData.scss'
import MonitorDosarePartsDatatable from './partials/MonitorDosarePartsDatatable'
// @ts-ignore
import {BPIAppearances} from '../BPIAppearances'

interface ExternalDataProps {
    match: any
}

export const ExternalData = ({match: {params}}: ExternalDataProps) => {
    const urlData = useMemo(() => {
        return {prefix: prefix, params: params}
    }, [params])

    const goToMonitorDosare = useCallback(() => {
        switch(params.secondaryAction) {
            case actionIds.MONITOR_DOSARE_HEARINGS:
                return (
                    <ExternalDataLayout urlData={urlData}>
                        <MonitorDosareHearings />
                    </ExternalDataLayout>
                )
            case actionIds.MONITOR_DOSARE_SUBSCRIPTIONS:
                return (
                    <ExternalDataLayout urlData={urlData}>
                        <MonitorDosareDatatable />
                    </ExternalDataLayout>
                )
            case actionIds.MONITOR_DOSARE_SUBSCRIPTIONS_PARTS:
                return (
                    <ExternalDataLayout urlData={urlData}>
                        <MonitorDosarePartsDatatable />
                    </ExternalDataLayout>
                )
            default:
                return (
                    <Redirect
                        to={`${urlData.prefix}/${actionIds.MONITOR_DOSARE}/${actionIds.MONITOR_DOSARE_HEARINGS}`}
                    />
                )
        }
    }, [params.secondaryAction, urlData])

    const goToMonitorBPI = useCallback(() => {
        switch(params.secondaryAction) {
            case actionIds.MONITOR_BPI_NOTIFICATIONS:
                return (
                    <ExternalDataLayout urlData={urlData}>
                        <Notifications appFilter='bpi' />
                    </ExternalDataLayout>
                )
            case actionIds.MONITOR_BPI_SUBSCRIPTIONS:
                return (
                    <ExternalDataLayout urlData={urlData}>
                        <MonitorBpiDatatable />
                    </ExternalDataLayout>
                )
            case actionIds.MONITOR_BPI_APPEARANCES:
                return (
                    <ExternalDataLayout urlData={urlData}>
                        <BPIAppearances appFilter='bpi' />
                    </ExternalDataLayout>
                )
            default:
                return (
                    <Redirect
                        to={`${urlData.prefix}/${actionIds.MONITOR_BPI}/${actionIds.MONITOR_BPI_APPEARANCES}`}
                    />
                )
        }
    }, [params.secondaryAction, urlData])

    switch(params.primaryAction) {
        case actionIds.MONITOR_DOSARE:
            return goToMonitorDosare()
        case actionIds.MONITOR_BPI:
            return goToMonitorBPI()
        default:
            return
    }
}

const ExternalDataLayout = ({children, urlData}: any) => {
    return (
        <div className='resource-details-content'>
            <SecondaryNavbar actions={actions} urlData={urlData}/>
            <div className='details-div'>
                <div className="details-content">
                    <div className="page-project-show">
                        {children}
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ExternalData