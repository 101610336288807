import React from 'react'

import _ from 'lodash'

import {Notification} from 'types/api'

// @ts-ignore
import {connect} from 'react-redux'
// @ts-ignore
import {slideoverTypes} from '../../../../redux/slideovers'

// @ts-ignore
import {Slideover} from '../../../../components/Slideover'
// @ts-ignore
import {Button} from '../../../../components/Button'
import {Link} from 'react-router-dom'

type BPISubscriptionProcessedNotificationDetailsProps = {
    open: boolean
    notification: Notification
}

export const BPISubscriptionProcessedNotificationDetails = (
    {open, notification}: BPISubscriptionProcessedNotificationDetailsProps
) => {

    return (
        <Slideover open={open && !_.isEmpty(notification)} title={notification.subject}>
            <div className="notification-details-container">
                <div className="notification-details-texts-container">
                    <p className="notification-details-changes-text">Mesaj</p>
                    <p className="notification-details-message">{notification.message}</p>
                </div>
                <Link to='/external-data/monitor-bpi/bpi-appearances'>
                    <Button
                        title='Vezi toate aparițiile'
                        className='button-cta'
                        fullWidth
                    />
                </Link>
            </div>
        </Slideover>
    )
}

const mapStateToProps = (state: any) => ({
    open: state.slideovers.type === slideoverTypes.NOTIFICATION_DETAILS,
    notification: state.notifications.currentNotification
})

export default connect(mapStateToProps)(BPISubscriptionProcessedNotificationDetails)