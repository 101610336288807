import {lightFormat} from 'date-fns'
import {isNull, join} from 'lodash'

export const columns = [
    {
        Header: 'Număr contract',
        accessor: 'internal_series',
        Cell: ({row: {original: contract}}) => (
            <p className='contract-name'>
                {contract.internal_series_name} ({contract.internal_series_number})
            </p>
        )
    },
    {
        Header: 'Dată generare',
        accessor: 'created',
        Cell: ({value: created}) => created ? lightFormat(new Date(created), 'dd/MM/yyyy HH:mm') : '-'
    },
    {
        Header: 'Nume',
        accessor: 'name'
    },
    {
        Header: 'Clienți',
        accessor: 'clients',
        Cell: ({value: clients}) => join(clients.map((client) => client.name), ', ')
    },
    {
        Header: 'Proiecte',
        accessor: 'projects',
        Cell: ({value: projects}) => join(projects.map((project) => project.name), ', ')
    },
    {
        Header: 'Serie barou',
        accessor: 'bar_series',
        Cell: ({row: {original: contract}}) => `${contract.bar_series_name} (${contract.bar_series_number})`
    },
    {
        Header: 'Document semnat încărcat',
        accessor: 'signed_file',
        Cell: ({value: signed_file}) => !isNull(signed_file) ? 'Da' : 'Nu'

    }
]
